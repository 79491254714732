import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HTTP_INTERCEPTORS
} from '@angular/common/http';
import { catchError, finalize, Observable, throwError } from 'rxjs';
import { TokenService } from '@/_services/token.service';
import { SpinnerService } from '@/_services/spinner.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(private tokenService: TokenService, private spinnerService: SpinnerService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    console.log(request)
    
    const token = this.tokenService.getToken()

    if(token !== null){
      let clone = request.clone({
        headers: request.headers.set('Authorization', 'bearer '+token)
      })
      console.log(clone)
      
      this.spinnerService.show();

      return next.handle(clone).pipe(
        finalize(() => {
              this.spinnerService.hide();
            }),
        catchError(error => {
          console.log(error)
          console.log('log error')

          if(error.status === 401){
            this.tokenService.clearToken()
          }
          return throwError(error.messgae)
        })
      )
    }
    
    return next.handle(request);

    // this.spinnerService.show();
    // return next.handle(request).pipe(
    //   finalize(() => {
    //     this.spinnerService.hide();
    //   })
    // );
  }
}

export const TokenInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: TokenInterceptor,
  multi: true
}
