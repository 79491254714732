import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import { AuthAdnGuard } from './_helpers/auth-adn.guard';
import { AuthGuard } from './_helpers/auth.guard';
import { ErrorComponent } from './_utils/error/error.component';
import { AuthGroupsGuard } from './_helpers/auth-groups.guard';


const routes: Routes = [
    {
        path: '', redirectTo: 'auth', pathMatch: 'full'
    },
    {
        path: 'auth', loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
    },
    {
        path: 'admin/church', loadChildren: () => import('./admin-church/admin-church.module').then(m => m.AdminChurchModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'admin/adn', loadChildren: () => import('./admin-adn/admin-adn.module').then(m => m.AdminAdnModule),
        canActivate: [AuthAdnGuard]
    },
    {
        path: 'admin/groups', loadChildren: () => import('./admin-groups/admin-groups.module').then(m => m.AdminGroupsModule),
        canActivate: [AuthGroupsGuard]
    },
    {path: '**', component: ErrorComponent}
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule {}
