import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import jwt_decode from "jwt-decode";

@Injectable({
  providedIn: 'root'
})
export class TokenService {
  public dataDecoded: any;

  constructor(private router: Router) { }

  saveToken(token: string): void{
    localStorage.setItem('token', token)
    //this.router.navigate(['admin'])
  }

  decodedToken(token: string){
     return this.dataDecoded = jwt_decode(token);
  }

  isLogged(){
    const token = localStorage.getItem('token')
    //console.log(token)
    return !! token
  }

  clearToken(): void {
    localStorage.removeItem('token')
    this.router.navigate(['auth'])
  }

  getToken(): string | null{
    return localStorage.getItem('token')
  }

}
