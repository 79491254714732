
    <h1 mat-dialog-title class="text-center">{{data.title}}</h1>
    <div mat-dialog-content>
        <form [formGroup]="memberLastNameForm" *ngIf="data && data.tableName== 'memberLastName'" (ngSubmit)="memberLastNameForm.status !== 'INVALID' && onConfirm(true, data.tableName, data.memberId)">
            <p class="text-center"> {{data.instruction}} </p>
            <mat-form-field  style="width: 100%;">
                <mat-label>{{data.label}} </mat-label>
                <input matInput formControlName="memberLastName" >
            </mat-form-field>
            <div mat-dialog-actions>
                <button mat-button type="button" (click)="onConfirm(false, data.tableName, data.memberId)" color="primary">Annuler</button>
                <button mat-button type="submit" color="warn">Valider</button>
            </div>
        </form>

        <form [formGroup]="memberFirstNameForm" *ngIf="data && data.tableName== 'memberFirstName'" (ngSubmit)="memberFirstNameForm.status !== 'INVALID' && onConfirm(true, data.tableName, data.memberId)">
            <p class="text-center"> {{data.instruction}} </p>
            <mat-form-field  style="width: 100%;">
                <mat-label>{{data.label}} </mat-label>
                <input matInput formControlName="memberFirstName" >
            </mat-form-field>
            <div mat-dialog-actions>
                <button mat-button type="button" (click)="onConfirm(false, data.tableName, data.memberId)" color="primary">Annuler</button>
                <button mat-button type="submit" color="warn">Valider</button>
            </div>
        </form>
        <form [formGroup]="memberPhoneForm" *ngIf="data && data.tableName== 'memberPhone'" (ngSubmit)="memberPhoneForm.status !== 'INVALID' && onConfirm(true, data.tableName, data.memberId)">
            <p class="text-center"> {{data.instruction}} </p>
            <mat-form-field  style="width: 100%;">
                <mat-label>{{data.label}} </mat-label>
                <input matInput formControlName="memberPhone" >
            </mat-form-field>
            <div mat-dialog-actions>
                <button mat-button type="button" (click)="onConfirm(false, data.tableName, data.memberId)" color="primary">Annuler</button>
                <button mat-button type="submit" color="warn">Valider</button>
            </div>
        </form>

        <form [formGroup]="memberEmailForm" *ngIf="data && data.tableName== 'memberEmail'" (ngSubmit)="memberEmailForm.status !== 'INVALID' && onConfirm(true, data.tableName, data.memberId)">
            <p class="text-center"> {{data.instruction}} </p>
            <mat-form-field  style="width: 100%;">
                <mat-label>{{data.label}} </mat-label>
                <input matInput formControlName="memberEmail" >
            </mat-form-field>
            <div mat-dialog-actions>
                <button mat-button type="button" (click)="onConfirm(false, data.tableName, data.memberId)" color="primary">Annuler</button>
                <button mat-button type="submit" color="warn">Valider</button>
            </div>
        </form>

        <form [formGroup]="memberInvitedByForm" *ngIf="data && data.tableName== 'memberInvitedBy'" (ngSubmit)="memberInvitedByForm.status !== 'INVALID' && onConfirm(true, data.tableName, data.memberId)">
            <p class="text-center"> {{data.instruction}} </p>
            <mat-form-field  style="width: 100%;">
                <mat-label>{{data.label}} </mat-label>
                <input matInput formControlName="memberInvitedBy" >
            </mat-form-field>
            <div mat-dialog-actions>
                <button mat-button type="button" (click)="onConfirm(false, data.tableName, data.memberId)" color="primary">Annuler</button>
                <button mat-button type="submit" color="warn">Valider</button>
            </div>
        </form>

        <form [formGroup]="memberOccupationForm" *ngIf="data && data.tableName== 'memberOccupation'" (ngSubmit)="memberOccupationForm.status !== 'INVALID' && onConfirm(true, data.tableName, data.memberId)">
            <p class="text-center"> {{data.instruction}} </p>
            <mat-form-field  style="width: 100%;">
                <mat-label>{{data.label}} </mat-label>
                <input matInput formControlName="memberOccupation" >
            </mat-form-field>
            <div mat-dialog-actions>
                <button mat-button type="button" (click)="onConfirm(false, data.tableName, data.memberId)" color="primary">Annuler</button>
                <button mat-button type="submit" color="warn">Valider</button>
            </div>
        </form>

        <form [formGroup]="memberNationalityForm" *ngIf="data && data.tableName== 'memberNationality'" (ngSubmit)="memberNationalityForm.status !== 'INVALID' && onConfirm(true, data.tableName, data.memberId)">
            <p class="text-center"> {{data.instruction}} </p>
            <mat-form-field  style="width: 100%;">
                <mat-label>{{data.label}} </mat-label>
                <input matInput formControlName="memberNationality" >
            </mat-form-field>
            <div mat-dialog-actions>
                <button mat-button type="button" (click)="onConfirm(false, data.tableName, data.memberId)" color="primary">Annuler</button>
                <button mat-button type="submit" color="warn">Valider</button>
            </div>
        </form>

        <form [formGroup]="memberFrequentedCommunityForm" *ngIf="data && data.tableName== 'memberFrequentedCommunity'" (ngSubmit)="memberFrequentedCommunityForm.status !== 'INVALID' && onConfirm(true, data.tableName, data.memberId)">
            <p class="text-center"> {{data.instruction}} </p>
            <mat-form-field  style="width: 100%;">
                <mat-label>{{data.label}} </mat-label>
                <input matInput formControlName="memberFrequentedCommunity" >
            </mat-form-field>
            <div mat-dialog-actions>
                <button mat-button type="button" (click)="onConfirm(false, data.tableName, data.memberId)" color="primary">Annuler</button>
                <button mat-button type="submit" color="warn">Valider</button>
            </div>
        </form>

        <form [formGroup]="memberDepartmentFrequentedForm" *ngIf="data && data.tableName== 'memberDepartmentFrequented'" (ngSubmit)="memberDepartmentFrequentedForm.status !== 'INVALID' && onConfirm(true, data.tableName, data.memberId)">
            <p class="text-center"> {{data.instruction}} </p>
            <mat-form-field  style="width: 100%;">
                <mat-label>{{data.label}} </mat-label>
                <input matInput formControlName="memberDepartmentFrequented" >
            </mat-form-field>
            <div mat-dialog-actions>
                <button mat-button type="button" (click)="onConfirm(false, data.tableName, data.memberId)" color="primary">Annuler</button>
                <button mat-button type="submit" color="warn">Valider</button>
            </div>
        </form>
        
        
    </div>
    
