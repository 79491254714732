import { ExcelDataImportGuestDto } from '@/_interfaces/ExcelDataImportGuestDto';
import { ExcelDataTribeDto } from '@/_interfaces/excelDataTribe';
import { GroupMember } from '@/_interfaces/groupMember';
import { Member } from '@/_interfaces/member';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AdminGroupsService {
  private apiUrl = environment.apiHost;

  constructor(private http: HttpClient) { }

  getAnswerImportExcelData(dataList: ExcelDataTribeDto[]): Observable<any>{
    return this.http.post(this.apiUrl+'/GroupMember/import', dataList);
  }

  AddAnswerImportGuestExcelData(dataList: ExcelDataImportGuestDto[]): Observable<any>{
    return this.http.post(this.apiUrl+'/Member/importGuestInBD', dataList);
  }

  /*getAnswerImportExcelData(dataList: any): Observable<any>{
    return this.http.get(this.apiUrl+'/GroupMember/import?datalist='+dataList);
  }*/

  UpdateMemberLastName(memberLastName: string, memberId: any): Observable<string>{    
    return this.http.put(this.apiUrl+'/Member/UpdateMemberLastName', memberLastName, {
      responseType: 'text',
      params: new HttpParams()
      .set('memberLastName', memberLastName)
      .set('memberId', memberId)
    });
  }

  UpdateMemberFirstName(memberFirstName: string, memberId: any): Observable<string>{    
    return this.http.put(this.apiUrl+'/Member/UpdateMemberFirstName', memberFirstName, {
      responseType: 'text',
      params: new HttpParams()
      .set('memberFirstName', memberFirstName)
      .set('memberId', memberId)
    });
  }

  UpdateMemberPhone(memberPhone: string, memberId: any): Observable<string>{    
    return this.http.put(this.apiUrl+'/Member/UpdateMemberPhone', memberPhone, {
      responseType: 'text',
      params: new HttpParams()
      .set('memberPhone', memberPhone)
      .set('memberId', memberId)
    });
  }

  UpdateMemberEmail(memberEmail: string, memberId: any): Observable<string>{    
    return this.http.put(this.apiUrl+'/Member/UpdateMemberEmail', memberEmail, {
      responseType: 'text',
      params: new HttpParams()
      .set('memberEmail', memberEmail)
      .set('memberId', memberId)
    });
  }

  UpdateMemberInvitedBy(memberInvitedBy: string, memberId: any): Observable<string>{    
    return this.http.put(this.apiUrl+'/Member/UpdateMemberInvitedBy', memberInvitedBy, {
      responseType: 'text',
      params: new HttpParams()
      .set('memberInvitedBy', memberInvitedBy)
      .set('memberId', memberId)
    });
  }

  UpdateMemberOccupation(memberOccupation: string, memberId: any): Observable<string>{    
    return this.http.put(this.apiUrl+'/Member/UpdateMemberOccupation', memberOccupation, {
      responseType: 'text',
      params: new HttpParams()
      .set('memberOccupation', memberOccupation)
      .set('memberId', memberId)
    });
  }

  UpdateMemberNationality(memberNationality: string, memberId: any): Observable<string>{    
    return this.http.put(this.apiUrl+'/Member/UpdateMemberNationality', memberNationality, {
      responseType: 'text',
      params: new HttpParams()
      .set('memberNationality', memberNationality)
      .set('memberId', memberId)
    });
  }

  UpdateMemberFrequentedCommunity(memberFrequentedCommunity: string, memberId: any): Observable<string>{    
    return this.http.put(this.apiUrl+'/Member/UpdateMemberFrequentedCommunity', memberFrequentedCommunity, {
      responseType: 'text',
      params: new HttpParams()
      .set('memberFrequentedCommunity', memberFrequentedCommunity)
      .set('memberId', memberId)
    });
  }

  UpdateMemberDepartmentFrequented(memberDepartmentFrequented: string, memberId: any): Observable<string>{    
    return this.http.put(this.apiUrl+'/Member/UpdateMemberDepartmentFrequented', memberDepartmentFrequented, {
      responseType: 'text',
      params: new HttpParams()
      .set('memberDepartmentFrequented', memberDepartmentFrequented)
      .set('memberId', memberId)
    });
  }

  getMemberGroupsByMemberId(memberId: any): Observable<any>{
    return this.http.get(this.apiUrl+'/Member/GetMemberGroupsByMemberId?memberId='+memberId);
  }

  getMemberById(memberId: any): Observable<any>{
    return this.http.get(this.apiUrl+'/Member/getById?id='+memberId);
  }

  getMemberGroup(accountId: any): Observable<any>{
    return this.http.get(this.apiUrl+'/Member/getGroupMembers?groupId='+accountId);
  }

  getMembersWithoutTribe(churchId: any): Observable<any>{
    return this.http.get(this.apiUrl+'/Member/getMembersWithoutTribe?churchId='+churchId);
  }

  deleteMemberGroup(memberGroupId: number): Observable<boolean>{
    return this.http.delete<boolean>(this.apiUrl+'/GroupMember/delete?groupMemberId='+memberGroupId);
  }

  addMemberGroup(memberGroup: GroupMember): Observable<string>{
    return this.http.post<string>(this.apiUrl+'/GroupMember/add', memberGroup);
  }

  getMemberPresentAtLeastTwice(churchId: any): Observable<any>{
    return this.http.get(this.apiUrl+'/EventMember/getAtTwoLeast?churchId='+churchId);
  }
}
