import { TokenService } from '@/_services/token.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGroupsGuard implements CanActivate {
  tokenDecodedAdnGuard: any;
  constructor(private router: Router, private tokenService: TokenService){}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    //return true;

    if(this.tokenService.isLogged()){
      this.tokenDecodedAdnGuard = this.tokenService.decodedToken(this.tokenService.getToken());

     if(this.tokenDecodedAdnGuard.AccountTypeLabel == 'ADMINISTRATEUR GROUPE'){
       return true;
     }
   }

   return this.router.navigate(['auth'])
  }
  
}
