import { TokenService } from '@/_services/token.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  tokenDecodedAdminGuard: any;
  constructor(private router: Router, private tokenService: TokenService){}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    //return false;

    if(this.tokenService.isLogged()){
      this.tokenDecodedAdminGuard = this.tokenService.decodedToken(this.tokenService.getToken());

      if(this.tokenDecodedAdminGuard.AccountTypeLabel == 'ADMINISTRATEUR EGLISE'){
        return true;
      }
    }

    return this.router.navigate(['auth'])
  }
  
}
