import { Member } from '@/_interfaces/member';
import { AdminGroupsService } from '@/_services/admin-groups.service';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss']
})
export class PopupComponent implements OnInit {
  member: any = null;
  memberUpdate: Member = null;

  memberLastNameForm = new UntypedFormGroup({
    memberLastName: new UntypedFormControl(null, [Validators.required])
  });
  
  memberFirstNameForm = new UntypedFormGroup({
    memberFirstName: new UntypedFormControl(null, [Validators.required]),
  });

  memberPhoneForm = new UntypedFormGroup({
    memberPhone: new UntypedFormControl(null, [Validators.pattern(/^[0-9]\d*$/), Validators.required, Validators.minLength(10)]),
  });

  memberEmailForm = new UntypedFormGroup({
    memberEmail: new UntypedFormControl(null, Validators.email),
  });

  memberInvitedByForm = new UntypedFormGroup({
    memberInvitedBy: new UntypedFormControl(null, Validators.required),
  });

  memberOccupationForm = new UntypedFormGroup({
    memberOccupation: new UntypedFormControl(null, Validators.required),
  });

  memberNationalityForm = new UntypedFormGroup({
    memberNationality: new UntypedFormControl(null, Validators.required),
  });

  memberFrequentedCommunityForm = new UntypedFormGroup({
    memberFrequentedCommunity: new UntypedFormControl(null, Validators.required),
  });

  memberDepartmentFrequentedForm = new UntypedFormGroup({
    memberDepartmentFrequented: new UntypedFormControl(null, Validators.required),
  });
  
  constructor(
    public dialogRef: MatDialogRef<PopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private adminGroupService: AdminGroupsService,
    private toastr: ToastrService,
  ) {}

  ngOnInit(): void {
    console.log(this.data.memberId);
    this.getMemberById(this.data.memberId);
  }

  UpdateMemberLastName(memberLastName: string, memberId: any){
    this.adminGroupService.UpdateMemberLastName(memberLastName, memberId).subscribe(
      (memberData: string) => {
        console.log(memberData);
        if (memberData == "VisitorNotExist") {
          console.log(memberData);
          this.toastr.error("Erreur ! Ce membre n'existe pas");
        }else if (memberData == "updateError") {
          console.log(memberData);
          this.toastr.error("Oups ! Une erreur est survenue. Veuillez reéssayer.");
        }else{
          console.log(memberData);
          this.toastr.success("Félicitations ! Modification effectuée avec succès.");
        }
      }, error => {
        console.log(error);
          this.toastr.error("Echec d'envoie du formulaire");
      }
    );
  }

  UpdateMemberFirstName(memberLastName: string, memberId: any){
    this.adminGroupService.UpdateMemberFirstName(memberLastName, memberId).subscribe(
      (memberData: string) => {
        console.log(memberData);
        if (memberData == "VisitorNotExist") {
          console.log(memberData);
          this.toastr.error("Erreur ! Ce membre n'existe pas");
        }else if (memberData == "updateError") {
          console.log(memberData);
          this.toastr.error("Oups ! Une erreur est survenue. Veuillez reéssayer.");
        }else{
          console.log(memberData);
          this.toastr.success("Félicitations ! Modification effectuée avec succès.");
        }
      }, error => {
        console.log(error);
          this.toastr.error("Echec d'envoie du formulaire");
      }
    );
  }

  UpdateMemberPhone(memberPhone: string, memberId: any){
    this.adminGroupService.UpdateMemberPhone(memberPhone, memberId).subscribe(
      (memberData: string) => {
        console.log(memberData);
        if (memberData == "VisitorNotExist") {
          console.log(memberData);
          this.toastr.error("Erreur ! Ce membre n'existe pas");
        }else if (memberData == "updateError") {
          console.log(memberData);
          this.toastr.error("Oups ! Une erreur est survenue. Veuillez reéssayer.");
        }else{
          console.log(memberData);
          this.toastr.success("Félicitations ! Modification effectuée avec succès.");
        }
      }, error => {
        console.log(error);
          this.toastr.error("Echec d'envoie du formulaire");
      }
    );
  }

  UpdateMemberEmail(memberEmail: string, memberId: any){
    this.adminGroupService.UpdateMemberEmail(memberEmail, memberId).subscribe(
      (memberData: string) => {
        console.log(memberData);
        if (memberData == "VisitorNotExist") {
          console.log(memberData);
          this.toastr.error("Erreur ! Ce membre n'existe pas");
        }else if (memberData == "updateError") {
          console.log(memberData);
          this.toastr.error("Oups ! Une erreur est survenue. Veuillez reéssayer.");
        }else{
          console.log(memberData);
          this.toastr.success("Félicitations ! Modification effectuée avec succès.");
        }
      }, error => {
        console.log(error);
          this.toastr.error("Echec d'envoie du formulaire");
      }
    );
  }

  UpdateMemberInvitedBy(memberInvitedBy: string, memberId: any){
    this.adminGroupService.UpdateMemberInvitedBy(memberInvitedBy, memberId).subscribe(
      (memberData: string) => {
        console.log(memberData);
        if (memberData == "VisitorNotExist") {
          console.log(memberData);
          this.toastr.error("Erreur ! Ce membre n'existe pas");
        }else if (memberData == "updateError") {
          console.log(memberData);
          this.toastr.error("Oups ! Une erreur est survenue. Veuillez reéssayer.");
        }else{
          console.log(memberData);
          this.toastr.success("Félicitations ! Modification effectuée avec succès.");
        }
      }, error => {
        console.log(error);
          this.toastr.error("Echec d'envoie du formulaire");
      }
    );
  }

  UpdateMemberOccupation(memberOccupation: string, memberId: any){
    this.adminGroupService.UpdateMemberOccupation(memberOccupation, memberId).subscribe(
      (memberData: string) => {
        console.log(memberData);
        if (memberData == "VisitorNotExist") {
          console.log(memberData);
          this.toastr.error("Erreur ! Ce membre n'existe pas");
        }else if (memberData == "updateError") {
          console.log(memberData);
          this.toastr.error("Oups ! Une erreur est survenue. Veuillez reéssayer.");
        }else{
          console.log(memberData);
          this.toastr.success("Félicitations ! Modification effectuée avec succès.");
        }
      }, error => {
        console.log(error);
          this.toastr.error("Echec d'envoie du formulaire");
      }
    );
  }

  UpdateMemberNationality(memberNationality: string, memberId: any){
    this.adminGroupService.UpdateMemberNationality(memberNationality, memberId).subscribe(
      (memberData: string) => {
        console.log(memberData);
        if (memberData == "VisitorNotExist") {
          console.log(memberData);
          this.toastr.error("Erreur ! Ce membre n'existe pas");
        }else if (memberData == "updateError") {
          console.log(memberData);
          this.toastr.error("Oups ! Une erreur est survenue. Veuillez reéssayer.");
        }else{
          console.log(memberData);
          this.toastr.success("Félicitations ! Modification effectuée avec succès.");
        }
      }, error => {
        console.log(error);
          this.toastr.error("Echec d'envoie du formulaire");
      }
    );
  }

  UpdateMemberFrequentedCommunity(memberFrequentedCommunity: string, memberId: any){
    this.adminGroupService.UpdateMemberFrequentedCommunity(memberFrequentedCommunity, memberId).subscribe(
      (memberData: string) => {
        console.log(memberData);
        if (memberData == "VisitorNotExist") {
          console.log(memberData);
          this.toastr.error("Erreur ! Ce membre n'existe pas");
        }else if (memberData == "updateError") {
          console.log(memberData);
          this.toastr.error("Oups ! Une erreur est survenue. Veuillez reéssayer.");
        }else{
          console.log(memberData);
          this.toastr.success("Félicitations ! Modification effectuée avec succès.");
        }
      }, error => {
        console.log(error);
          this.toastr.error("Echec d'envoie du formulaire");
      }
    );
  }

  UpdateMemberDepartmentFrequented(memberDepartmentFrequented: string, memberId: any){
    this.adminGroupService.UpdateMemberDepartmentFrequented(memberDepartmentFrequented, memberId).subscribe(
      (memberData: string) => {
        console.log(memberData);
        if (memberData == "VisitorNotExist") {
          console.log(memberData);
          this.toastr.error("Erreur ! Ce membre n'existe pas");
        }else if (memberData == "updateError") {
          console.log(memberData);
          this.toastr.error("Oups ! Une erreur est survenue. Veuillez reéssayer.");
        }else{
          console.log(memberData);
          this.toastr.success("Félicitations ! Modification effectuée avec succès.");
        }
      }, error => {
        console.log(error);
          this.toastr.error("Echec d'envoie du formulaire");
      }
    );
  }

  getMemberById(memberId: any){
    this.adminGroupService.getMemberById(memberId).subscribe(
      (memberData: any) => {
        //console.log(memberData);
        this.member = memberData;
        console.log(this.member);
      }
    );
  }

  validate(tableName: string, memberId: any){
    
    switch (tableName) {
      case 'memberLastName':
        console.log(this.memberLastNameForm.value.memberLastName);
        this.UpdateMemberLastName(this.memberLastNameForm.value.memberLastName, memberId);
        break;

        case 'memberFirstName':
          console.log(this.memberFirstNameForm.value.memberFirstName);
          this.UpdateMemberFirstName(this.memberFirstNameForm.value.memberFirstName, memberId);
          break;

        case 'memberPhone':
          console.log(this.memberPhoneForm.value.memberPhone);
          this.UpdateMemberPhone(this.memberPhoneForm.value.memberPhone, memberId);
          break;

          case 'memberEmail':
          console.log(this.memberEmailForm.value.memberEmail);
          this.UpdateMemberEmail(this.memberEmailForm.value.memberEmail, memberId);
          break;

          case 'memberInvitedBy':
          console.log(this.memberInvitedByForm.value.memberInvitedBy);
          this.UpdateMemberInvitedBy(this.memberInvitedByForm.value.memberInvitedBy, memberId);
          break;

          case 'memberOccupation':
          console.log(this.memberOccupationForm.value.memberOccupation);
          this.UpdateMemberOccupation(this.memberOccupationForm.value.memberOccupation, memberId);
          break;

          case 'memberNationality':
          console.log(this.memberNationalityForm.value.memberNationality);
          this.UpdateMemberNationality(this.memberNationalityForm.value.memberNationality, memberId);
          break;

          case 'memberFrequentedCommunity':
          console.log(this.memberFrequentedCommunityForm.value.memberFrequentedCommunity);
          this.UpdateMemberFrequentedCommunity(this.memberFrequentedCommunityForm.value.memberFrequentedCommunity, memberId);
          break;

          case 'memberDepartmentFrequented':
          console.log(this.memberDepartmentFrequentedForm.value.memberDepartmentFrequented);
          this.UpdateMemberDepartmentFrequented(this.memberDepartmentFrequentedForm.value.memberDepartmentFrequented, memberId);
          break;
      
      default:
        break;
    }

  }

  onConfirm(result: boolean, request:string, id: any): void {
    if (result) {
      this.dialogRef.close(result);
      //console.log('ok');
      this.validate(request, id)
    }else{
      this.dialogRef.close(result);
    }
    
  }




}
